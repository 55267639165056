import { ApiCore } from '../_utilities/core';

const expertisePrintout = new ApiCore({
  url: '/expertise/vehicle-expertises/:id/printout',
});

export default {
  getVehicles: (queryString) =>
    new ApiCore({
      url: '/finance/vehicles',
    })
      .query({ queryString })
      .read(),
  getVehicle: (id) =>
    new ApiCore({
      url: '/finance/vehicles/:id',
    })
      .query({ urlParams: { id } })
      .read(),
  createExpertisePrintoutPdf: (id) =>
    expertisePrintout.query({ urlParams: { id } }).create(),
};
