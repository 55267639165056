import { ApiCore } from '../_utilities/core';

const photos = new ApiCore({
  url: '/expertise/photos/:expertiseId',
});

const photo = new ApiCore({
  url: '/expertise/photos/:expertiseId/:id',
  responseType: 'blob',
});
const photoThumbnail = new ApiCore({
  url: '/expertise/photos/:expertiseId/:id/thumbnail',
  responseType: 'blob',
});
export const photoVisibleByDealerFirstThumbnailUrl =
  '/expertise/photos/:vehicleId/visible-by-dealer/first/thumbnail';

const photosVisibleByDealer = new ApiCore({
  url: '/expertise/photos/:vehicleId/visible-by-dealer',
});

export default {
  getPhotos: (expertiseId) =>
    photos.query({ urlParams: { expertiseId } }).read(),
  sendPhoto: (expertiseId, data) =>
    photos.query({ urlParams: { expertiseId } }).create(data),
  getPhoto: (expertiseId, id) =>
    photo.query({ urlParams: { expertiseId, id } }).read(),
  getPhotoThumbnail: (expertiseId, id) =>
    photoThumbnail.query({ urlParams: { expertiseId, id } }).read(),
  updatePhoto: (expertiseId, id, data) =>
    photo.query({ urlParams: { expertiseId, id } }).update(data),
  deletePhoto: (expertiseId, id) =>
    photo.query({ urlParams: { expertiseId, id } }).delete(),
  getPhotosVisibleByDealer: (vehicleId) =>
    photosVisibleByDealer.query({ urlParams: { vehicleId } }).read(),
};
